// Scroll to target smoothly
jQuery(function ($) {

    slidebox_app();

    function slidebox_app() {

        var dismiss_app = getCookie("slidebox_app_dismiss");

        if (dismiss_app == 0 || dismiss_app == null || dismiss_app == "" || dismiss_app == undefined) {

            //add cookie class to body*/
            $('#slidebox-app').addClass('visible');
            $('#slidebox-app').removeClass('remove');
            $('body').addClass('slidebox-app-visible');
            $('#slidebox-app-show').removeClass('visible');
            $('#slidebox-app-show').addClass('remove');
        }
        if (dismiss_app == 1) {
            $('#slidebox-app-show').removeClass('remove');
            $('#slidebox-app-show').addClass('visible');
        }
    }

    function setCookie(c_name, value, exdays) {

        var exdate = new Date();
        exdate.setDate(exdate.getDate() + exdays);
        var c_value = escape(value) + ((exdays == null) ? "" : "; expires=" + exdate.toUTCString());
        document.cookie = c_name + "=" + c_value + "; path=/";

    }

    function getCookie(c_name) {

        var i, x, y, ARRcookies = document.cookie.split(";");
        for (i = 0; i < ARRcookies.length; i++) {

            x = ARRcookies[i].substr(0, ARRcookies[i].indexOf("="));
            y = ARRcookies[i].substr(ARRcookies[i].indexOf("=") + 1);
            x = x.replace(/^\s+|\s+$/g, "");

            if (x == c_name) {
                return unescape(y);
            }
        }
    }

    $("#slidebox-app-dismiss").click(function () {
        $('body').removeClass('slidebox-app-visible');
        $('#slidebox-app').addClass('remove');
        $('#slidebox-app-show').addClass('visible');
        $('#slidebox-app-show').removeClass('remove');
        setCookie("slidebox_app_dismiss", 1, 1);
    });
    $("#slidebox-app-show").click(function () {
        $('#slidebox-app').removeClass('remove');
        $('body').addClass('slidebox-app-visible');
        $('#slidebox-app').addClass('visible');
        $('#slidebox-app-show').addClass('remove');
        $('#slidebox-app-show').removeClass('visible');
        setCookie("slidebox_app_dismiss", 0, 1);
    });
});

/**
 * Initialize slidebox.
 */
jQuery(function ($) {

    slidebox_career();

    function slidebox_career() {

        var dismiss_career = getCookie("slidebox_career_dismiss");

        if (dismiss_career == 0 || dismiss_career == null || dismiss_career == "" || dismiss_career == undefined) {

            //add cookie class to body*/
            $('#slidebox-career').addClass('visible');
            $('#slidebox-career').removeClass('remove');
            $('body').addClass('slidebox-career-visible');
            $('#slidebox-career-show').removeClass('visible');
            $('#slidebox-career-show').addClass('remove');
        }
        if (dismiss_career == 1) {
            $('#slidebox-career-show').removeClass('remove');
            $('#slidebox-career-show').addClass('visible');
        }
    }


    function setCookie(c_name, value, exdays) {

        var exdate = new Date();
        exdate.setDate(exdate.getDate() + exdays);
        var c_value = escape(value) + ((exdays == null) ? "" : "; expires=" + exdate.toUTCString());
        document.cookie = c_name + "=" + c_value + "; path=/";

    }

    function getCookie(c_name) {

        var i, x, y, ARRcookies = document.cookie.split(";");
        for (i = 0; i < ARRcookies.length; i++) {

            x = ARRcookies[i].substr(0, ARRcookies[i].indexOf("="));
            y = ARRcookies[i].substr(ARRcookies[i].indexOf("=") + 1);
            x = x.replace(/^\s+|\s+$/g, "");

            if (x == c_name) {
                return unescape(y);
            }
        }
    }

    $("#slidebox-career-dismiss").click(function () {
        $('body').removeClass('slidebox-career-visible');
        $('#slidebox-career').addClass('remove');
        $('#slidebox-career-show').addClass('visible');
        $('#slidebox-career-show').removeClass('remove');
        setCookie("slidebox_career_dismiss", 1, 1);
    });

    $("#slidebox-career-show").click(function () {
        $('#slidebox-career').removeClass('remove');
        $('body').addClass('slidebox-career-visible');
        $('#slidebox-career').addClass('visible');
        $('#slidebox-career-show').addClass('remove');
        $('#slidebox-career-show').removeClass('visible');
        setCookie("slidebox_career_dismiss", 0, 1);
    });
});


/**
 * Filter function for page "Motivation"
 * 
 * @package leytbild
 */

// Set selection to current filter
const elem          = document.getElementById("content-motivation");

if (elem) {
  
  const filterData    = elem.getAttribute("data-filter");
  const filter        = filterData.toLowerCase();

  lbFilterSelection(filter);

  /**
   * Filter selection
   */
  function lbFilterSelection(c) {
    var x, i;
    x = document.getElementsByClassName("filterDiv");
    //if (c == "global") c = "";
    for (i = 0; i < x.length; i++) {
      lbRemoveClass(x[i], "show");
      if (x[i].className.indexOf(c) > -1) lbAddClass(x[i], "show");
    }
  }

  /**
   * Add classes
   */
  function lbAddClass(element, name) {
    var i, arr1, arr2;
    arr1 = element.className.split(" ");
    arr2 = name.split(" ");
    for (i = 0; i < arr2.length; i++) {
      if (arr1.indexOf(arr2[i]) == -1) {element.className += " " + arr2[i];}
    }
  }

  /**
   * Remove classes
   */
  function lbRemoveClass(element, name) {
    var i, arr1, arr2;
    arr1 = element.className.split(" ");
    arr2 = name.split(" ");
    for (i = 0; i < arr2.length; i++) {
      while (arr1.indexOf(arr2[i]) > -1) {
        arr1.splice(arr1.indexOf(arr2[i]), 1);     
      }
    }
    element.className = arr1.join(" ");
  }

  /**
   * Add active class to the current button (highlight it)
   */
  var btnContainer = document.getElementById("content-motivation");
  var btns = btnContainer.getElementsByClassName("filter-button");
  for (var i = 0; i < btns.length; i++) {
    btns[i].addEventListener("click", function(){
      var current = document.getElementsByClassName("filter-active");
      current[0].className = current[0].className.replace(" filter-active", "");
      this.className += " filter-active";
      var filterSelection = current[0].getAttribute("data-filter-selection");
      lbFilterSelection(filterSelection);
      
    });
  }

  
  jQuery(function ($) {

  function lbRefreshFilterSelection() {
        // Get current tab
        var contentCurrentTab = $('#content-motivation').find('.tab-pane.active');
        // Get div containing filter buttons
        var filterButtons = $('#filter-buttons');
        // Get filter button for global content
        var filterGlobalButton = $(filterButtons).find('.filter-button-global');
        // Get filter button for department content
        var filterDepartmentButton = $(filterButtons).find('.department-dropdown-toggle');
        // Get currently selected filter
        var filterDepartmentButtonLabel = $(filterDepartmentButton).attr('data-value');
        var filterDepartmentCurrentItem = $(filterButtons).find('.filter-active');
        // Get global content
        var contentGlobalItem = $(contentCurrentTab).find('.filterDiv.global');
        // Get current department content
        var contentDepartmentCurrentItem = $(contentCurrentTab).find('.filterDiv.show');
        // Get inner div of current department content
        var contentDepartmentCurrentItemInner = $(contentDepartmentCurrentItem).find('.department-content');
        // Check if department content is missing
        if (contentDepartmentCurrentItemInner.hasClass('empty')) {
          // Remove and add classes for content
          contentDepartmentCurrentItem.removeClass('show');
          contentGlobalItem.addClass('show');
          // Remove and ad classes for filters
          filterDepartmentButton.removeClass('contains-filter-active');
          filterDepartmentButton.html(filterDepartmentButtonLabel + '<span class="caret"></span>');
          filterDepartmentCurrentItem.removeClass('filter-active');
          filterGlobalButton.addClass('filter-active');
        }      
  }


  /**
   * Relocate filter buttons
   */

    $('.tab-link[data-bs-toggle="tab"]').on('shown.bs.tab', function (e) {
        // Get id of requested tab
        var tabTarget = $(e.target).attr('data-bs-target');
        // Find container element in requested tab
        var target = $(tabTarget).find('.filter-container');
        // Relocate filter buttons to requested tab
        $("#filter-buttons").detach().appendTo(target);
        // Refresh Filter Buttons
        lbRefreshFilterSelection();

    });

    $(".department-dropdown li").on('click', function() {
        // Change button content to current department title
        $(this).parents(".content-filter-departments").find('.btn').html($(this).text() + ' <span class="caret"></span>');
        //$(this).parents(".content-filter-departments").find('.btn').val($(this).data('value'));
        $(".department-dropdown-toggle").addClass("contains-filter-active");
        
    });

    $(".filter-button-global").on('click', function() {
        var filterButtons = $('#filter-buttons');
        var filterDepartmentButton = $(filterButtons).find('.department-dropdown-toggle');
        var filterDepartmentButtonLabel = $(filterDepartmentButton).attr('data-value');
        $(this).parents(".content-filter-departments").find('.department-dropdown-toggle').html($('.department-dropdown-toggle').text(filterDepartmentButtonLabel) + ' <span class="caret"></span>');
        //$(this).parents(".content-filter-departments").find('.dropdown-toggle').val($(this).data('value'));
        $(".department-dropdown-toggle").removeClass("contains-filter-active");
    });
  });
};
